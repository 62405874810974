.salon-page-wrapper {
  display: grid;
  grid-gap: 10px;
  margin: 1em;
}

.salon-page-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.book-button {
  margin: auto;
}

.salon-bio {
  background-color: lightgray;
}

@media only screen and (min-width: 600px) {
  .salon-page-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
  }

  .salon-page-logo {
    grid-column: 1/3;
    grid-row: 1;
  }

  .book-button {
    grid-column: 3;
    grid-row: 1;
  }

  .salon-bio {
    grid-column: 1;
    grid-row: 2;
  }

  .address {
    grid-column: 3;
    grid-row: 2;
  }

  .hours {
    grid-column: 1;
    grid-row: 2;
  }
}
